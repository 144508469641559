export default {
    optionDonut() {
        return {
            chart: {
                type: 'donut',
                width: '100%',
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '70%'
                    },
                },
            },
            stroke: {
                colors: undefined,
            },
            tooltip: {
                enabled: true,
                y: {
                    formatter: (val) => {
                        return val.toLocaleString()
                    },
                },
            },
            colors: ['#221ecd', '#6461dc', '#00c1d4', '#4cd3e0'],
            legend: {
                position: 'left',
                offsetX: 25,
                offsetY: 40,
                itemMargin: {
                    vertical: 8
                },
                onItemClick: {
                    toggleDataSeries: false
                },
                onItemHover: {
                    highlightDataSeries: false
                },
            },
            labels: [],
        }
    },
}