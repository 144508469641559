import IService from './iservice';
import config from "@/config";
import store from "@/store/store";

const server_base = config[config.stage].car_stats_server;

class CrossSellService extends IService {
    constructor(http) {
        super(http);
        this.store = store;
    }

    async dealersList(limit, skip, search) {
        try {
            this.addLoading('list');
            const response = await this.http.get(`${server_base}/sales/dealers?limit=${limit}&skip=${skip}&search=${search}`);
            this.delLoading('list');
            return response.data;
        } catch (e) {
            this.delLoading('list');
            return null;
        }
    }

    async dealerDetails(seller, address, zip_code) {
        try {
            this.addLoading('dealer_details');
            const response = await this.http.get(`${server_base}/sales/dealer-details`, {
                seller, address: encodeURIComponent(address), zip_code
            });
            this.delLoading('dealer_details');
            return response.data;
        } catch (e) {
            this.delLoading('dealer_details');
            return null;
        }
    }

    async lienholdersList(limit, skip, search) {
        try {
            this.addLoading('list');
            const response = await this.http.get(`${server_base}/sales/lienholders?limit=${limit}&skip=${skip}&search=${search}`);
            this.delLoading('list');
            return response.data;
        } catch (e) {
            this.delLoading('list');
            return null;
        }
    }

    async summaries(type, search, dateRange, condition, state, limit, skip, sort) {
        try {
            const filters = {search, dateRange, condition, state, sort}

            this.addLoading(type);
            const response = await this.http.get(`${server_base}/sales/summaries/${type}?limit=${limit}&skip=${skip}`, filters);
            this.delLoading(type);
            return response.data;
        } catch (e) {
            this.delLoading(type);
            return null;
        }
    }

    async getTopBySeller(seller, limit, type, piker) {
        try {
            const response = await this.http.get(`${server_base}/sales/top/seller/${seller}?limit=${limit}&type=${type}`, piker);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async getTopByBoundary(boundary, limit, type, piker) {
        try {
            const query = Object.assign(boundary, piker);

            const response = await this.http.get(`${server_base}/sales/top/boundary?limit=${limit}&type=${type}`, query);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async getTop(limit, type, piker) {
        try {
            const response = await this.http.get(`${server_base}/sales/top?limit=${limit}&type=${type}`, piker);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async chartByDealer(seller_id, criteria, select, search) {
        try {
            const response = await this.http.get(`${server_base}/api/cross-sale/count/${seller_id}`, {
                criteria,
                select,
                search
            });
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async countByDealer(seller_id, limit, skip, criteria, search) {
        try {
            const response = await this.http.get(`${server_base}/api/cross-sale/chart/${seller_id}`, {
                limit,
                skip,
                criteria,
                search
            });
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async getZipcodeGeojson(zipcode) {
        try {
            const response = await this.http.get(`${server_base}/maps/zipcode/${zipcode}`);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async locations(type, viewport, value) {
        try {
            const response = await this.http.get(`${server_base}/sales-location`, {type, viewport, value});
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async getTopMakes(limit, skip, boundary, picker, yearRange, staked) {
        try {
            this.addLoading('make_list');

            const query = `?limit=${limit}&skip=${skip}&boundary=${boundary}&picker=${picker}&yearRange=${yearRange}&staked=${staked}`;
            const response = await this.http.get(`${server_base}/sales/top/makes/${query}`);

            this.delLoading('make_list');
            return response.data;
        } catch (e) {
            this.delLoading('make_list');
            return null;
        }
    }

    async getTopModels(limit, skip, boundary, picker, yearRange, staked, make) {
        try {
            this.addLoading('model_list');

            const query = `?make=${make}&limit=${limit}&skip=${skip}&boundary=${boundary}&picker=${picker}&yearRange=${yearRange}&staked=${staked}`;
            const response = await this.http.get(`${server_base}/sales/top/models/${query}`);

            this.delLoading('model_list');
            return response.data;
        } catch (e) {
            this.delLoading('model_list');
            return null;
        }
    }
}

export default CrossSellService;