import Dh from '@/helpers/date.helper';

import Utils from '@/helpers/utils';
import States from '@/helpers/states';
import chart from './chartConfig';

import CrossSell from '@/services/cross.sell.service';

const service = new CrossSell();

export default {
    name: 'DealerSummaries',

    components: {},

    data() {
        return {
            empty: {
                sales: false,
                chart: false,
            },

            seriesDonut: [],
            labelsDonut: [],

            sales: {
                total: 0, retail: 0, fleet: 0, cars: 0, trucks: 0
            },

            dealers: [],
            currentPage: 1,
            totalRows: 0,
            perPage: 25,

            searchTerm: '',
            filterTimeout: null,

            dateRange: {from: '', to: ''},
            appliedDateRange: {from: '', to: ''},

            conditions: ['New', 'Used'],
            condition: '', // Car condition

            states: States,
            state: 'FL', // Seller state

            sort: {
                total: -1
            }
        }
    },

    computed: {
        optionDonut() {
            const optionDonut = chart.optionDonut();
            optionDonut.labels = this.labelsDonut;
            return optionDonut;
        },

        dateRangeText() {
            if (!this.appliedDateRange.from && !this.appliedDateRange.to) {
                return this.$t('date')
            } else if (this.appliedDateRange.from && this.appliedDateRange.to) {
                return `${Dh.customFormat(this.appliedDateRange.from, 'MMM, YYYY')} ${this.$t('to')} ${Dh.customFormat(this.appliedDateRange.to, 'MMM, YYYY')}`
            } else if (!this.appliedDateRange.from) {
                return `${this.$t('To')} ${Dh.customFormat(this.appliedDateRange.to, 'MMM, YYYY')}`
            } else if (!this.appliedDateRange.to) {
                return `${this.$t('From')} ${Dh.customFormat(this.appliedDateRange.from, 'MMM, YYYY')}`
            }
        },

        conditionText() {
            if (this.condition) {
                return this.condition
            }
            return this.$t('condition')
        },

        stateText() {
            if (this.state) {
                const idx = this.states.findIndex(el => el.abbreviation === this.state)
                return this.states[idx].name
            }
            return this.$t('state')
        }
    },

    mounted() {
        this.list();
    },

    methods: {
        list() {
            this.dealerSummaries();
            this.fleetRetailSummaries();
            this.salesSummaries();
        },

        async dealerSummaries() {
            const skip = this.perPage * (this.currentPage - 1);
            const res = await service.summaries('dealer', this.searchTerm, this.appliedDateRange, this.condition, this.state, this.perPage, skip, this.sort);
            if (res && !res.error) {
                this.dealers = res.data.dealers;
                this.totalRows = res.data.count;
            }
        },

        async fleetRetailSummaries() {
            const res = await service.summaries('fleet_retail', this.searchTerm, this.appliedDateRange, this.condition, this.state);
            if (res && !res.error) {
                this.labelsDonut = []
                this.seriesDonut = []

                this.empty.chart = !(res.data.total > 0)

                if (res.data.total > 0) {
                    this.labelsDonut.push(this.$t('retail_cars'), this.$t('retail_trucks'))
                    this.seriesDonut.push(res.data.retail_cars, res.data.retail_trucks)

                    this.labelsDonut.push(this.$t('fleet_cars'), this.$t('fleet_trucks'))
                    this.seriesDonut.push(res.data.fleet_cars, res.data.fleet_cars)
                }
            }
        },

        async salesSummaries() {
            const res = await service.summaries('sales', this.searchTerm, this.appliedDateRange, this.condition, this.state);
            if (res && !res.error) {
                this.sales = res.data
                this.empty.sales = !(res.data.total > 0)
            }
        },

        clearCondition() {
            this.condition = '';
            this.list();
        },

        clearDates() {
            this.dateRange = {from: '', to: ''}
        },

        applyDates() {
            this.$hideModals();
            this.appliedDateRange = {...this.dateRange};
            this.list();
        },

        highlight(text) {
            return text ? Utils.HighlightText(text, this.searchTerm) : "N/A";
        },

        setCondition(val) {
            this.condition = val;
            this.$hideModals();
            this.list();
        },

        setState(val) {
            this.state = val.abbreviation;
            this.$hideModals();
            this.list();
        },

        setPerPage(num) {
            this.perPage = +(num);
            this.$hideModals();
            this.dealerSummaries();
        },

        toggleSort(type) {
            this.sort[type] = this.sort[type] === 1 ? -1 : 1
            this.dealerSummaries();
        },

        next() {
            this.currentPage += 1;
            this.dealerSummaries();
        },

        prev() {
            this.currentPage -= 1;
            this.dealerSummaries();
        },
    },

    watch: {
        searchTerm() {
            clearTimeout(this.filterTimeout);
            this.filterTimeout = setTimeout(() => {
                this.list();
            }, 500);
        },
    },

    filters: {
        formatNumber(val) {
            return val || '-'
            //return val.toLocaleString()
        },
    },
}